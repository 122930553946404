import { post } from '@/utils/request'


export function add(data) {
    return post('/room/room/addRoom', data)
}


export function getList(data) {
    return post('/room/room/roomList', data)
}
 // 房间组列表
 export function getRoomGroupList(data) {
    return post('/room/room/roomGroupList', data)
}
export function getInfo(data) {
    return post('/room/room/roomInfo', data)
}

export function edit(data) {
    return post('/room/room/editRoom', data)
}

export function del(data) {
    return post('/room/room/delRoom', data)
}

export function getStoreList(data) {
    return post('/room/room_group/shopList', data)
}
// 房间规格列表

export function specModelList(data) {
    return post('/room/room/specModelList', data)
}

// 模型列表
export function getModel(data) {
    return post('/room/room/getModel', data)
}

// 标记脏房


export function setCleaning(data) {
    return post('/room/cleaning/setCleaning', data)
}
// 标记维修房
export function setRepair(data) {
    return post('/room/repair/setRepair', data)
}

// 完成保洁
export function doneCleaning(data) {
    return post('/room/cleaning/completeCleaning', data)
}
//完成维修

export function doneRepair(data) {
    return post('/room/repair/completeRepair', data)
}