<template>
  <div>
    <el-card>
      <div class="menu-box">
        <h2>房间管理</h2>
        <div class="box-bd">
          <!-- 头 -->
          <el-row :gutter="20">
            <el-col :span="6">
              <el-select
                @change="handleShopChange()"
                v-model="shop_id"
                placeholder="请选择店铺"
                clearable
              >
                <el-option
                  v-for="item in shopList"
                  :key="item.shop_id"
                  :label="item.shop_name"
                  :value="item.shop_id"
                >
                </el-option>
              </el-select>
              <!-- <el-button type="primary" @click="pAdd()">添加房间</el-button> -->
            </el-col>
            <el-col :span="6">
              <el-select
                @change="handleGroupChange()"
                v-model="query.group_id"
                placeholder="请选择房间组"
                clearable
              >
                <el-option
                  v-for="item in roomGroupList"
                  :key="item.group_id"
                  :label="item.name"
                  :value="item.group_id"
                >
                </el-option>
              </el-select>
              <!-- <el-button type="primary" @click="pAdd()">添加房间</el-button> -->
            </el-col>
            <el-col :span="6">
              <el-button type="primary" @click="pAdd()">添加房间</el-button>
            </el-col>
          </el-row>
          <!-- 表格 -->
          <el-table
            v-loading="tableLoading"
            class="task-table"
            :data="tableData"
          >
            <el-table-column prop="name" label="房名"> </el-table-column>
            <el-table-column prop="group_name" label="房间组名">
            </el-table-column>

            <!-- <el-table-column prop="shop_name" label="所属店铺">
            </el-table-column> -->

            <el-table-column prop="class_status" label="状态">
              <template slot-scope="scope">
                <el-tag type="danger" v-if="scope.row.status === 0">
                  禁用
                </el-tag>
                <el-tag type="success" v-if="scope.row.status === 1">
                  空闲-干净房
                </el-tag>
                <el-tag type="success" v-if="scope.row.status === 2">
                  部分预定-干净房
                </el-tag>
                <el-tag type="success" v-if="scope.row.status === 3">
                  满员-干净房
                </el-tag>
                <el-tag type="warning" v-if="scope.row.status === 4">
                  部分预定-脏房
                </el-tag>
                <el-tag type="warning" v-if="scope.row.status === 5">
                  空闲-脏房
                </el-tag>
                <el-tag type="warning" v-if="scope.row.status === 6">
                  保洁中-脏房
                </el-tag>
                <el-tag type="warning" v-if="scope.row.status === 7">
                  保洁中-空房
                </el-tag>
                <el-tag type="primary" v-if="scope.row.status === 8">
                  维修中
                </el-tag>
              </template>
            </el-table-column>
            <el-table-column width="400" label="操作">
              <template slot-scope="scope">
                <el-button
                  size="mini"
                  type="primary"
                  icon="el-icon-s-flag"
                  v-if="
                    scope.row.status === 1 ||
                      scope.row.status === 2 ||
                      scope.row.status === 3
                  "
                  @click="dirtySignal(scope.row)"
                  >设为脏房</el-button
                >
                <!-- <el-button
                  size="mini"
                  type="success"
                  icon="el-icon-s-opportunity"
                  v-if="
                    scope.row.status === 4 ||
                      scope.row.status === 5 ||
                      scope.row.status === 6
                  "
                  @click="doneCleaning(scope.row)"
                  >完成保洁</el-button
                > -->
                <el-button
                  size="mini"
                  type="info"
                  icon="el-icon-setting"
                  v-if="scope.row.status !== 8"
                  @click="repairSignal(scope.row)"
                  >设置维修</el-button
                >
                <el-button
                  size="mini"
                  type="info"
                  icon="el-icon-check"
                  v-if="scope.row.status === 8"
                  @click="doneRepairing(scope.row)"
                  >完成维修</el-button
                >
                <el-button
                  size="mini"
                  type="warning"
                  icon="el-icon-edit"
                  @click="handleEdit(scope.row)"
                  >编辑</el-button
                >
                <el-button
                  size="mini"
                  type="danger"
                  icon="el-icon-delete"
                  @click="remove(scope.row)"
                  >删除</el-button
                >
              </template>
            </el-table-column>
          </el-table>
          <el-pagination
            :pager-count="15"
            @current-change="pageChange"
            @size-change="sizeChange"
            :page-sizes="[7, 15, 20]"
            layout="total, sizes, prev, pager, next"
            :page-size="this.query.limit"
            background
            :total="exp.total"
          >
          </el-pagination>
        </div>
      </div>
    </el-card>
    <!-- 添加/删除对话框 -->
    <el-dialog
      v-dialogDrag
      :title="title"
      :visible.sync="dialogVisible"
      :before-close="formClose"
    >
      <el-form :rules="formRules" ref="formRef" :model="form">
        <el-form-item
          v-if="addOrEdit === 1"
          label="店铺名"
          :label-width="formLabelWidth"
        >
          <template>
            <span style="color:red">{{ shopName }}</span>
          </template>
        </el-form-item>
        <el-form-item label="房间组" :label-width="formLabelWidth">
          <el-select
            @change="handleGroupChange()"
            v-model="form.group_id"
            placeholder="请选择房间组"
            clearable
            style="width:100%"
          >
            <el-option
              v-for="item in roomGroupList"
              :key="item.group_id"
              :label="item.name"
              :value="item.group_id"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item
          prop="name"
          label="房间名称"
          :label-width="formLabelWidth"
        >
          <el-input
            @change="handleChange"
            v-model="form.name"
            placeholder="请填写房间名称"
          ></el-input>
        </el-form-item>
        <el-form-item prop="number" label="人数" :label-width="formLabelWidth">
          <el-input
            @change="handleChange"
            v-model="form.number"
            type="number"
            min="1"
            placeholder="请填写人数"
          ></el-input>
        </el-form-item>
        <el-form-item label="模型名称" :label-width="formLabelWidth">
          <el-select
            style="width:100%"
            @change="handleModelChange()"
            v-model="model_id"
            placeholder="请选择模型名称"
            clearable
          >
            <el-option
              v-for="item in modelList"
              :key="item.model_id"
              :label="item.model_name"
              :value="item.model_id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-row style="margin:20px 0 20px 110px;">
          <div
            style="display: flex;"
            v-for="item in modelData.son"
            :key="item.spec_id"
          >
            <span style="margin-right:30px"> {{ item.spec_name }}</span>
            <el-checkbox-group v-model="checkList">
              <el-tag type="success" v-if="item.son.length === 0"
                >该规格下暂时没有属性</el-tag
              >
              <el-checkbox
                @change="handleCheckboxChange(service_spec)"
                v-else
                v-for="itm in item.son"
                :key="itm.item_id"
                :label="itm.item_id"
                >{{ itm.item_name }}</el-checkbox
              >
            </el-checkbox-group>
          </div>
        </el-row>
        <el-form-item
          v-if="addOrEdit === 2"
          prop="status"
          label="状态"
          :label-width="formLabelWidth"
        >
          <el-switch
            @change="handleChange"
            v-model="form.status"
            :active-value="1"
            :inactive-value="0"
            active-color="#13ce66"
            inactive-color="#ff4949"
          />
        </el-form-item>

        <el-table
          border
          class="dynamic-table"
          ref="dynamic-table"
          :data="tableRes"
        >
          <el-table-column width="500" label="规格">
            <el-table-column
              width="100"
              v-for="item in columnRes"
              :key="item.spec_id"
              :label="item.spec_name"
              :prop="item.spec_id.toString()"
            >
            </el-table-column>
          </el-table-column>

          <el-table-column label="价格" prop="price">
            <template v-slot="scope">
              <input
                type="number"
                class="original-input"
                @input="priceChange(tableRes, scope.$index, scope.row)"
                v-model="scope.row.price"
                style="width:100px"
              />
            </template>
          </el-table-column>

          <el-table-column label="小时价格" prop="unit_price">
            <template v-slot="scope">
              <input
                type="number"
                class="original-input"
                @input="unitpriceChange(tableRes, scope.$index, scope.row)"
                v-model="scope.row.unit_price"
                style="width:100px"
              />
            </template>
          </el-table-column>
        </el-table>
      </el-form>
      <!-- 功能栏 -->
      <div slot="footer" class="dialog-footer">
        <el-button @click="cancelAE()">取 消</el-button>
        <el-button type="primary" :loading="btnLoading" @click="confirmAE()"
          >确 定</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import lodash from 'lodash'

import {
  add,
  getList,
  getInfo,
  edit,
  getRoomGroupList,
  del,
  getStoreList,
  specModelList,
  getModel,
  setCleaning,
  setRepair,
  doneCleaning,
  doneRepair,
} from '@/api/room/roommanage.js'
export default {
  data() {
    return {
      imgList: [],
      service_spec: [],
      roomGroupList: [],
      columnidRes: [],
      shop_id: 5,
      checkList: [],
      model_id: '',
      isEdit: false,
      tableLoading: false,
      warningText: '',
      exp: {
        total: 0,
      },
      shopList: [],
      btnLoading: false,

      query: {
        page: 1,
        page_code: '',
        limit: 7,
        group_id: 0,
      },

      dialogVisible: false,
      changeDialogVisible: false,
      formRules: {
        name: {
          required: true,
          message: '请填写房间名称',
          trigger: 'blur',
        },
      },
      title: '添加房间',
      addOrEdit: 1,
      formLabelWidth: '160px',

      form: {
        name: '',
        number: 1,
        group_id: '',
      },
      group_id: '',
      tableData: [],
      modelList: [],
      modelData: {},
      tableRes: [],
      columnRes: [],
    }
  },
  created() {
    this.getOptionList().then(() => {
      this.getList()
    })
  },
  mounted() {},
  watch: {
    columnidRes() {
      let res = []

      this.modelData.son.forEach((spec) => {
        this.columnidRes.forEach((id) => {
          //   console.log(id)
          if (id === spec.spec_id) {
            // console.log('===')
            res.push(spec)
          }
        })
      })
      this.columnRes = res
    },
  },
  computed: {
    imgUrl() {
      return this.$store.state.imgUrl
    },
    shopName() {
      let name = ''
      this.shopList.forEach((item) => {
        if (item.shop_id == this.shop_id) {
          //   console.log('==')
          name = item.shop_name
        }
      })
      return name
    },
    roomGroupName() {
      let name = ''
      this.roomGroupList.forEach((item) => {
        if (item.group_id == this.query.group_id) {
          //   console.log('==')
          name = item.name
        }
      })
      return name
    },
  },

  methods: {
    doneRepairing(row) {
      this.$confirm('确定完成维修？')
        .then(async (_) => {
          const { data: res } = await doneRepair({
            room_id: row.room_id,
          })
          if (res.code === 1) {
            this.$message.success('维修已完成')
            this.getList()
          } else {
            this.$message.error(res.message)
          }

          // this.getDepartmentList()
          done()
        })
        .catch((_) => {})
    },

    repairSignal(row) {
      this.$confirm('将该房标记为维修房？')
        .then(async (_) => {
          const { data: res } = await setRepair({
            room_id: row.room_id,
          })
          if (res.code === 1) {
            this.$message.success('标记成功！')
            this.getList()
          } else {
            this.$message.error(res.message)
          }

          // this.getDepartmentList()
          done()
        })
        .catch((_) => {})
    },
    dirtySignal(row) {
      console.log(row)
      this.$confirm('将该房标记为脏房？')
        .then(async (_) => {
          const { data: res } = await setCleaning({
            room_id: row.room_id,
          })
          if (res.code === 1) {
            this.$message.success('标记成功！')
            this.getList()
          } else {
            this.$message.error(res.message)
          }

          // this.getDepartmentList()
          done()
        })
        .catch((_) => {})
    },
    async getOptionList() {
      const { data: res } = await getStoreList()
      // console.log(res)
      if (res.code === 1) {
        this.shopList = res.data
      } else {
        this.$message.error('获取店铺数据失败！')
      }
      const { data: res1 } = await getRoomGroupList({ shop_id: this.shop_id })
      //  console.log(res)
      if (res1.code === 1) {
        this.roomGroupList = res1.data
        // if (this.roomGroupList.length > 0) {
        //   console.log('>0')
        //   this.group_id = this.roomGroupList[0].group_id
        //   this.query.group_id = this.group_id
        // }
      } else {
        this.$message.error('获取房间组数据失败！')
      }

      const { data: res2 } = await specModelList()

      if (res2.code === 1) {
        this.modelList = res2.data
      } else {
        this.$message.error('获取房间模型失败！')
      }
    },

    pageChange(val) {
      this.query.page = val
      this.getList()
    },
    sizeChange(val) {
      this.query.limit = val
      this.getList()
    },
    async getList() {
      this.tableLoading = true
      const { data: res } = await getList(this.query)
      // console.log(res)
      if (res.code === 1) {
        this.tableData = res.data.data
        this.exp.total = res.data.total
      } else {
        this.$message.error('获取数据失败！')
      }
      this.tableLoading = false
    },
    // 添加/编辑商品对话框关闭事件
    formClose() {
      this.$confirm('确认关闭？')
        .then((_) => {
          this.cancelAE()
          // this.getDepartmentList()
          done()
        })
        .catch((_) => {})
    },
    handleChange() {
      this.isEdit = true
    },
    handleChangeClose(done) {
      this.$confirm('确认关闭？')
        .then((_) => {
          this.changeDialogVisible = false
          this.getList()
          done()
        })
        .catch((_) => {})
    },
    // 取消
    cancelAE() {
      this.title = '添加房间'
      this.addOrEdit = 1
      this.isEdit = false
      this.form = {
        name: '',
        status: '',
        group_id: '',
        number: 1,
      }
      this.checkList = []
      this.tableRes = []
      if (this.$refs.formRef) {
        setTimeout(() => {
          this.$refs.formRef.clearValidate()
        }, 30)
      }

      this.dialogVisible = false
    },
    priceChange(table, index, row) {
      //console.log(index)
      console.log(row.price)

      //this.tableRes[index].price = row.price
      this.$set(table, index, row)
      //this.$forceUpdate()
    },
    unitpriceChange(table, index, row) {
      //console.log(index)
      console.log(row.unit_price)

      //this.tableRes[index].price = row.price
      this.$set(table, index, row)
      //this.$forceUpdate()
    },
    // 新增打开
    pAdd(row) {
      //  console.log(row)
      //   if (this.query.group_id === 0) {
      //     this.$message.info('请选择除所有房间以外的房间组再添加房间！')
      //     return
      //   }

      this.dialogVisible = true
    },
    // 编辑打开
    async handleEdit(row) {
      // console.log(row)
      this.dialogVisible = true
      this.title = '编辑房间'
      this.addOrEdit = 2
      const { data: res } = await getInfo({
        room_id: row.room_id,
      })
      // console.log(res.data)
      //   this.form.class_name = res.data.class_name
      //   this.form.class_status = res.data.class_status
      this.form.room_id = res.data.room_id
      this.form.group_id = res.data.group_id
      this.model_id = res.data.model_id
      this.form.number = res.data.number
      this.form.name = res.data.name
      this.form.status = res.data.status

      const service_spec = res.data.spec_list

      this.service_spec = service_spec
      let checkArr = []
      if (service_spec.length !== 0) {
        service_spec.forEach((item) => {
          //   if (item.spec_key.indexOf('-')) {
          //     const keyArr = item.spec_key.split('-')
          //     // console.log(keyArr)
          //     keyArr.forEach((i) => {
          //       checkArr.push(i)
          //     })
          //   } else {
          //     checkArr.push(item.spec_key)
          //   }
          service_spec.forEach((item) => {
            const keyArr = item.spec_key.split('-')
            // console.log(keyArr)
            keyArr.forEach((i) => {
              checkArr.push(i)
            })
          })
        })
      }
      checkArr = Array.from(new Set(checkArr))
      for (let i = 0; i < checkArr.length; i++) {
        checkArr[i] = Number(checkArr[i])
      }
      //console.log(checkArr)
      this.checkList = checkArr
      this.handleModelChange().then(() => {
        this.handleCheckboxChange(service_spec)
      })
      //  this.handleCheckboxChange(service_spec)
    },

    // 删除
    remove(row) {
      this.$confirm('确认删除？')
        .then(async (_) => {
          const { data: res } = await del({
            room_id: row.room_id,
          })
          if (res.code === 1) {
            this.$message.success('删除成功！')
            this.getList()
          } else {
            this.$message.error(res.message)
          }
          this.cancelAE()
          // this.getDepartmentList()
          done()
        })
        .catch((_) => {})
    },
    confirmAE() {
      this.$refs.formRef.validate(async (valid) => {
        if (valid) {
          if (this.addOrEdit === 1) {
            // 添加

            let query = lodash.cloneDeep(this.form)

            var formData = new FormData()
            formData.append('group_id', query.group_id)
            formData.append('number', query.number)
            formData.append('name', query.name)
            query.spec_ids = []
            if (this.tableRes.length !== 0) {
              this.tableRes.forEach((item) => {
                let obj = {}
                let spec_key = []
                let spec_key_name = []
                var reg = /^[0-9]{1,20}$/

                for (let key in item) {
                  // console.log(key)
                  if (key === 'price') {
                    obj.price = item[key]
                  } else if (key === 'unit_price') {
                    obj.unit_price = item[key]
                  } else if (!reg.test(key)) {
                    console.log(key)
                    spec_key.push(item[key])
                    spec_key_name.push(key)
                    console.log(spec_key_name)
                    console.log(item[key])
                  }
                }
                let str1 = spec_key.join('-')
                let str2 = spec_key_name.join('-')
                console.log(str1)
                console.log(str2)
                obj.spec_key_name = str2
                obj.spec_key = str1

                console.log(obj)
                query.spec_ids.push(obj)
              })
            }
            query.spec_ids = JSON.stringify(query.spec_ids)
            formData.append('spec_ids', query.spec_ids)

            this.btnLoading = true
            const { data: res } = await add(formData)
            if (res.code === 1) {
              this.$message.success('添加成功')
              this.getList()
              this.cancelAE()
            } else {
              this.$message.error(res.message)
            }
            this.btnLoading = false
          } else {
            // 编辑

            let query = lodash.cloneDeep(this.form)

            var formData = new FormData()
            formData.append('name', query.name)
            formData.append('number', query.number)
            formData.append('room_id', query.room_id)
            formData.append('status', query.status)
            formData.append('group_id', query.group_id)
            query.spec_ids = []
            if (this.tableRes.length !== 0) {
              this.tableRes.forEach((item) => {
                let obj = {}
                let spec_key = []
                let spec_key_name = []
                var reg = /^[0-9]{1,20}$/
                let flag = false
                for (let key in item) {
                  // console.log(key)
                  if (key === 'price') {
                    obj.price = item[key]
                  } else if (key === 'unit_price') {
                    obj.unit_price = item[key]
                  } else if (key === 'room_spec_price_id') {
                    obj.room_spec_price_id = item[key]
                  } else if (key === 'room_id') {
                    obj.room_id = item[key]
                    flag = true
                  } else if (key === 'spec_key') {
                    console.log(item[key], key)
                    obj.spec_key = item[key]
                  } else if (reg.test(key)) {
                    console.log(key)
                    spec_key_name.push(item[key])
                    //spec_key.push(key)
                    console.log(spec_key_name)
                  }
                }

                obj.room_id = query.room_id

                let str2 = spec_key_name.join('-')

                console.log(str2)
                obj.spec_key_name = str2

                console.log(obj)
                query.spec_ids.push(obj)
              })
            }
            query.spec_ids = JSON.stringify(query.spec_ids)
            formData.append('spec_ids', query.spec_ids)
            this.btnLoading = true

            const { data: res } = await edit(formData)
            if (res.code === 1) {
              this.$message.success('编辑成功')
              this.cancelAE()
              this.getList()
            } else {
              this.$message.error(res.message)
            }

            this.btnLoading = false
          }
        }
      })
    },
    async handleShopChange() {
      this.query.group_id = 0
      const { data: res1 } = await getRoomGroupList({ shop_id: this.shop_id })
      if (res1.code === 1) {
        this.roomGroupList = res1.data
      } else {
        this.$message.error('获取房间组数据失败！')
      }
      this.getList()
    },
    handleGroupChange() {
      this.getList()
    },
    async handleModelChange() {
      //this.checkList = []
      const { data: res } = await getModel({
        model_id: this.model_id,
      })
      this.modelData = res.data
      // console.log(this.modelData)
    },
    multi1(arr1, arr2) {
      if (arr1.length == 0) {
        // arr1 是空数组 []
        return arr2
      }
      if (arr2.length == 0) {
        return arr1
      }
      let res = []
      for (let i = 0; i < arr1.length; i++) {
        for (let j = 0; j < arr2.length; j++) {
          res.push(String(arr1[i]) + ',' + String(arr2[j]))
        }
      }
      return res
    },

    allGroups1(arr) {
      return arr.reduce((total, cur) => {
        let ress = this.multi1(total, cur)
        // console.log(ress);
        return ress
      }, [])
    },
    handleCheckboxChange(spec) {
      this.tableRes = []
      //    console.log(this.checkList)
      let res = []
      // spec_id和规格值组成的二维数组[['17-冰'],['18-抹茶'],['19-珍珠']]
      let twoDimensionSpecArr = []

      // 处理表格动态列数据
      //  console.log(this.modelData.son)

      this.modelData.son.forEach((spec) => {
        if (this.checkList.length === 0) {
          this.columnRes = []
          return
        }
        let oneDimensionSpecArr = []
        let oneDimensionItemArr = []

        this.checkList.forEach((id) => {
          spec.son.forEach((item) => {
            //console.log(item)
            if (item.item_id === id) {
              // 处理列id数组
              res.push(spec.spec_id)
              res = [...new Set(res)]
              //  console.log(res)
              this.columnidRes = res

              // 处理二维数组

              let tempStr =
                item.spec_id + '-' + item.item_name + '-' + item.item_id

              let tempStr1 = item.item_name

              oneDimensionSpecArr.push(tempStr)
              // 不带id
              oneDimensionItemArr.push(tempStr1)
            }
          })
        })
        // 生成规格二维数组
        twoDimensionSpecArr.push(oneDimensionSpecArr)
      })
      // 排列组合好后的一维数组 spec_id组
      let res1 = this.allGroups1(twoDimensionSpecArr)
      console.log(res1)
      if (this.addOrEdit === 1) {
        // 添加
        res1.forEach((item) => {
          //  console.log(item)
          let itemArr = item.split(',')
          let arrObj = {}
          itemArr.forEach((i) => {
            //   console.log(i)
            let f = i.split('-') //['18','冰']
            console.log(f)
            f.forEach((i) => {
              this.$set(arrObj, f[0], f[1])
              this.$set(arrObj, f[1], f[2])
              this.$set(arrObj, 'unit_price', '')
              this.$set(arrObj, 'price', '')
            })
            //  console.log(arrObj)
          })
          this.tableRes.push(arrObj)
        })
      } else {
        // 编辑
        res1.forEach((item) => {
          //   console.log(item)
          let itemArr = item.split(',')
          let arrObj = {}
          itemArr.forEach((i) => {
            //   console.log(i)
            let f = i.split('-') //['27','四人间','102']
            f.forEach((i) => {
              this.$set(arrObj, f[0], f[1])
              this.$set(arrObj, f[1], f[2])
            })
          })
          this.tableRes.push(arrObj)

          // 处理表格中的数据回显
          this.tableRes.forEach((item) => {
            let itemidArr = []
            for (let key in item) {
              if (
                isNaN(parseInt(key)) &&
                key !== 'spec_key' &&
                key !== 'price' &&
                key !== 'unit_price' &&
                key !== 'room_id' &&
                key !== 'room_spec_price_id'
              ) {
                itemidArr.push(item[key])
                console.log(item[key])
              }
            }
            console.log(itemidArr)
            item.spec_key = itemidArr.join('-')
            console.log(item)

            if (spec.length !== 0) {
              spec.forEach((s) => {
                console.log(item.spec_key)
                console.log(spec)

                if (s.spec_key == item.spec_key) {
                  console.log('==')
                  item.room_spec_price_id = s.room_spec_price_id
                  item.room_id = s.room_id

                  item.unit_price = parseInt(s.unit_price).toFixed(2)

                  item.price = parseInt(s.price).toFixed(2)
                } else {
                  console.log('不等于')
                }
              })
            }
          })

          // this.tableRes.forEach((item) => {
          //   console.log(item)
          //   let itemidArr = []

          //   for (let key in item) {
          //     if (isNaN(parseInt(key)) && key !== 'spec_key') {
          //       itemidArr.push(Number(item[key]))
          //     }
          //   }

          //   item.spec_key = itemidArr.join('-')

          //   if (spec.length !== 0) {
          //     spec.forEach((s) => {

          //       console.log(item.spec_key)
          //       console.log(spec)

          //       if (s.spec_key == item.spec_key) {
          //         console.log('==')
          //         item.room_spec_price_id = s.room_spec_price_id
          //         item.room_id = s.room_id

          //         item.unit_price = parseInt(s.unit_price).toFixed(2)

          //         item.price = parseInt(s.price).toFixed(2)

          //       } else {
          //         console.log('不等于')
          //       }
          //     })
          //   }
          // })
        })
      }
    },
  },
}
</script>

<style scoped>
.content {
  display: flex;
  align-items: center;
}
.content .warning-icon {
  width: 44px;
  height: 32px;
  margin-right: 10px;
  background: url('../../../assets/exclamationmark.png') no-repeat;
}
.original-input {
  border: 1px solid #dcdfe6;
  border-radius: 4px;
  height: 20px;
}
</style>
